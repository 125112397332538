import React, { useContext, useEffect, useState } from 'react'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { numberFormatterInPercentage } from '@helpers/number-formatter'
import { GetAnalyticsProResponseDTO } from '@interfaces/analytics-pro'

import { AnalyticsCovenantDataBuilder } from '../analytics-covenant-data-builder'

import AnalyticsProTableItem from './table'

const CovenantChart = ({
  data,
  showTableSwitch,
}: {
  data: GetAnalyticsProResponseDTO
  showTableSwitch: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const [selectedCohort, setSelectedCohort] = useState<string>('Summary')

  const covenantBuilder = new AnalyticsCovenantDataBuilder(
    setSelectedCohort,
    selectedCohort,
    data
  )
  const {
    tableColumns,
    tableData,
    tableHeaderData,
    chartSeries,
    chartData,
    invertColours,
    ySetting,
  } = covenantBuilder.build()

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const exportData = [
    ...tableColumns.map((s: any) => {
      return {
        title: s.title,
        field: s.field,
        align: s.align,
        width: s.width,
        render: (r: any) => {
          return typeof r[s.field] === 'number'
            ? numberFormatterInPercentage(r[s.field] / 100)
            : r[s.field]
        },
      }
    }),
  ]

  return (
    <MultiToggleLayout
      overrideSwitch={showTableSwitch}
      toggles={[]}
      disableTooltip={true}
      chart={
        <Chart
          loading={isProcessing}
          id={`${data.chart_id}__${company?.slug_name}_${appliedFilters.activeFacilityId}`}
          yLabel={''}
          yFormat={covenantBuilder.getLabelFormat(data)}
          xLabel="Month on Books (MoB)"
          xAxisType="CategoryAxis"
          data={chartData}
          series={chartSeries}
          exportable={true}
          exportableColumn={exportData}
          exportableGroupBy={'cohort'}
          ySetting={ySetting}
        />
      }
      toggleData={
        <AnalyticsProTableItem
          tableData={tableData}
          tableColumns={tableColumns}
          maxVal={1}
          tableHeaderData={tableHeaderData}
          stickyLeftCount={1}
          includeHeatMap={true}
          invertColours={invertColours}
        />
      }
    />
  )
}

export default CovenantChart
