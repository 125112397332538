import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Chart from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { useGetRiskData } from '@helpers/risk-charts-data-hook'
import { DataResponse, RiskData } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { CollectionService } from '@services/api-analytics/risk-collection'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const CollectionRate = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()

  const { appliedFilters, optionFilters } = useContext(AuthContext)
  const { categoryTypes = [], activeType, currency = 'USD' } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const {
    fetchedDataAggregated,
    data,
    growthRatios,
    cohort,
    error,
    isFetching,
    isAggregate,
  } = useGetRiskData(
    CollectionService.getCollectionRate,
    'collection_rate',
    true
  )

  let maxVal = 0

  const chartData = cohort.map((c: RiskData<DataResponse>) => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(
      (d: RiskData<DataResponse>) => d.cohort === c.cohort
    )
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_denom = parseFloat(
        (cohortData?.[0]?.denom ?? 0).toString()
      )
      result.type_all_numer = parseFloat(
        (cohortData?.[0]?.numer ?? 0).toString()
      )
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find((cd: RiskData<DataResponse>) => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        result[`type_${ct}_denom`] = parseFloat(
          (cohortCategory?.denom ?? 0).toString()
        )
        result[`type_${ct}_numer`] = parseFloat(
          (cohortCategory?.numer ?? 0).toString()
        )
        if (result[`type_${ct}`] > maxVal) {
          maxVal = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const collecRateSeries: any[] = isAggregate
    ? [
        {
          label: `All`,
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.00a%',
          field: `type_${ct}`,
          color,
          type: 'SmoothedXLineSeries',
          hasBullet: true,
        }
      })

  const header_data = [
    collecRateSeries.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_denom`]: 'Total Payments Scheduled',
        [`${s.field}_numer`]: `Total Payments Received`,
        [s.field]: 'Collection Rate (%)',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...collecRateSeries.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_numer`,
          title: header_data[0][`${s.field}_numer`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_numer`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_denom`,
          title: header_data[0][`${s.field}_denom`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_denom`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `Collection Rate (%) ${isAggregate ? '' : `(${s.label})`}`,
          head: { colSpan: 3, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]
  return (
    <MultiToggleLayout
      toggles={false}
      staticTipInfo={
        <div>
          The amount collected on loans compared to how much was expected
          according to the schedule.
          <br />
          <br />
          Formula: Total Payment (payments table) / Total Payment (schedules
          table) as long as the date_due or date_paid matches the specific
          month.
        </div>
      }
      exchangeRateIndicator={showHistoricalRateIndicator}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionRate_by_${activeType}`}
          yLabel="Percentage"
          ySetting={maxVal < 5 ? { max: 5, maxPrecision: 1 } : {}}
          yFormat="#.00a%"
          data={chartData}
          series={collecRateSeries as any}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? displayRates(display_rates)
              : undefined
          }
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      stat={<StatisticL5 {...growthRatios} />}
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          headerData={header_data}
          columns={table_columns}
          fitInView={true}
        />
      }
    />
  )
}

export default CollectionRate
