import React, { useContext } from 'react'

import MultiLevelTable from '@components/multi-level-table'
import AuthContext from '@contexts/auth'
import { GetAnalyticsProResponseDTO } from '@interfaces/analytics-pro'

import { AnalyticsDataBuilder } from '../analytics-chart-data-builder'

const AnalyticsProNumberTableItem = ({
  data,
}: {
  data: GetAnalyticsProResponseDTO
}) => {
  const { appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeType, calculationDate, currency } = appliedFilters
  const { categoryTypeOptions = [] } = optionFilters

  const builder = new AnalyticsDataBuilder(
    data,
    calculationDate,
    activeType,
    categoryTypeOptions
  )
  const { numberTableColumns, numberTableData } = builder.build()

  return (
    <div className="h-full">
      <div className="flex flex-col overflow-auto h-full">
        <MultiLevelTable
          dataSet={{ cols: numberTableColumns, data: numberTableData }}
          currency={currency}
          defaultCurrency={currency}
          hasCustomStyling={true}
        />
      </div>
    </div>
  )
}
export default AnalyticsProNumberTableItem
