import React from 'react'

import Table from '@components/table'

const AnalyticsProTableItem = ({
  tableData,
  tableColumns,
  stickyLeftCount,
  tableHeaderData,
  maxVal = 0,
  includeHeatMap = false,
  invertColours,
}: {
  tableData: any
  tableColumns: any
  stickyLeftCount?: number
  tableHeaderData?: any[]
  maxVal?: number
  includeHeatMap?: boolean
  invertColours?: boolean
}) => {
  return (
    <Table
      data={tableData}
      columns={tableColumns as any}
      stickyLeftCount={stickyLeftCount}
      headerData={tableHeaderData}
      includeHeatMap={includeHeatMap}
      maxVal={maxVal}
      invertColours={invertColours}
      fitInView={true}
    />
  )
}
export default AnalyticsProTableItem
